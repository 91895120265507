import React, { useState, useEffect } from "react";
import { useAccount, WagmiConfig, createClient, useContract } from "wagmi";
import { ethers } from "ethers";
import Web3 from "web3";
import {
	ConnectButton,
	RainbowKitProvider,
	getDefaultWallets,
	darkTheme,
	wallet,
} from "@rainbow-me/rainbowkit";
import merge from "lodash.merge";
import { chain, configureChains } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import "@rainbow-me/rainbowkit/styles.css";
import wine from "./bottle.png";
import wine2 from "./wine2.png";
import vik from './vik.png';
import wineContainer from "./wineContainer.jpg";
import logo from "./logo.svg";
import "./App.css";
import ABI from "./abi.json";
import {
	useNavigate,
	useLocation,
	Routes,
	Route,
	BrowserRouter as Router,
} from "react-router-dom";
import Claim from "./Claim"; // assuming Claim.js is in the same directory as App.js
import { polygon, sepolia } from "viem/chains";

const { chains, provider } = configureChains(
	[polygon],
	[
		alchemyProvider({ alchemyId: process.env.REACT_APP_ALCHEMY_ID }),
		publicProvider(),
	]
);

const { connectors } = getDefaultWallets({
	appName: "My RainbowKit App",
	chains,
});

const wagmiClient = createClient({
	autoConnect: true,
	connectors,
	provider,
});

function WalletInfo() {
	const account = useAccount();
	const [isWalletConnected, setIsWalletConnected] = useState(false);
	const [web3, setWeb3] = useState(null);
	const [contract, setContract] = useState(null);
	const [mintAmount, setMintAmount] = useState(1); // Initial mint amount
	const [success, setSuccess] = useState();

	const navigate = useNavigate();

	const handleDecrement = () => {
		if (mintAmount > 1) {
			setMintAmount(mintAmount - 1);
		}
	};

	const handleIncrement = () => {
		if (mintAmount < 12) {
			setMintAmount(mintAmount + 1);
		}
	};

	const contractAddress = "0x8b429db808e5c13bc9d63976c6aecf17411fbb12";

	const mintNFT = async () => {
		try {
			const contractAddress =
				"0x8b429db808e5c13bc9d63976c6aecf17411fbb12";
			if (!account || !account.connector) {
				alert("Please connect your wallet first.");
				return;
			}
			const signer = await account.connector.getSigner();
			if (!signer) {
				alert("Unable to get signer from the connected wallet.");
				return;
			}
			const contract = new ethers.Contract(contractAddress, ABI, signer);

			// transaction overrides
			const transactionOverrides = {
				gasLimit: ethers.utils.hexlify(1000000), // 1 million gas limit, adjust as necessary
			};

			let transaction;

			const priceInWei = ethers.utils.parseEther("750");
			const totalValue = priceInWei.mul(
				ethers.BigNumber.from(mintAmount)
			);

			transaction = await contract.mint(mintAmount, {
				...transactionOverrides,
				value: totalValue, // Adding the value to the transaction overrides
			});

			await transaction.wait();
			setSuccess(true);
		} catch (error) {
			console.error("An error occurred!", error);
		}
	};

	useEffect(() => {
		if (account.address) {
			setIsWalletConnected(true);
		}
	}, [account.address]);

	return (
		<div className="image-container2">
			<img src={wine} alt="wine" className="right-side-image" />
			<ConnectButton
				className="button"
				label="Buy your NFT"
				accountStatus={"address"}
				chainStatus="none"
				showBalance={false}
			/>
			{isWalletConnected ? (
				<div>
					<div className="slider-container">
						<div className="slider-value">{mintAmount}</div>
						<input
							className="mint-slider"
							type="range"
							min="1"
							max="12"
							value={mintAmount}
							onChange={(event) =>
								setMintAmount(Number(event.target.value))
							}
						/>
					</div>{" "}
					{success ? (
						<h3>NFT Minted</h3>
					) : (
						<button
							className="header-button"
							style={{ marginTop: "10px" }}
							onClick={mintNFT}>
							Confirm
						</button>
					)}
				</div>
			) : (
				""
			)}
		</div>
	);
}

function App2() {
	const navigate = useNavigate();
	const goToClaimPage = () => {
		navigate("/claim");
	};

	const [selectedOption, setSelectedOption] = useState("EN");
	const [isOpen, setIsOpen] = useState(false);

	const handleOptionSelect = (option) => {
		setSelectedOption(option);
		setIsOpen(false);
	};

	const options = ["EN", "ES", "PT"]; // Define your options here

	return (
		<WagmiConfig client={wagmiClient}>
			<RainbowKitProvider
				theme={darkTheme({
					accentColor: "#A32036",
					accentColorForeground: "white",
					borderRadius: "none",
					fontStack: "rounded",
					overlayBlur: "small",
				})}
				chains={ chains }>
				<div className="container" >
					{ selectedOption === "EN" && (
						<section>
							<div className="app top-apps">
								 <header className="app-header">
									<a
										className="top-logo"
										href="https://www.vikwine.com/"
										target="_blank"
										rel="noopener noreferrer">
										<img src={logo} alt="logo" />
									</a>
									<div className="header-right">
										<button
											className="dropdown-button"
											onClick={() => setIsOpen(!isOpen)}>
											{ selectedOption || "EN" }
											 <i className="arrow down"></i>
										</button>
										{isOpen && (
											<ul
												className={`dropdown-menu ${
													isOpen ? "open" : ""
												}`}>
												{options.map((option, index) => (
													<li
														key={index}
														onClick={() =>
															handleOptionSelect(
																option
															)
														}>
														{option}
													</li>
												))}
											</ul>
										)}
										<button
											className="header-button"
											style={ {
												marginLeft: "10px",
												fontWeight: "500",
											}}
											onClick={goToClaimPage}>
											Claim
										</button>
									</div>
								</header> 
								<main className="main-content hero-content">
									<div className="hero-content-head">
										<div className="text-container">
											<h2 className="subtitle">
											VIK NFT
											</h2>
											<h3>
												2018 Limited Edition
											</h3>
										</div>
										<WalletInfo />
										</div>
										<div className="hero-text-container">
										<a href="#main-app">
													<i className="arrow down"></i> <span>LEARN MORE </span>
													</a>
										<p>
										Viña VIK's latest innovation is a limited release of only 50 NFTs, offering exclusivity, traceability <br></br>and peace of mind to buyers of the highly regarded VIK 2018
<br></br><br></br>The limited release consists of 50 NFTs, each representing a double magnum of the special edition of VIK 2018 NFT.<br></br>On purchasing the NFT,Non-Fungible Token, an owner can trade their digital asset or redeem it as a token <br></br>and collect their physical bottle of VIK 2018. One of the benefits of wine NFTs is the traceability offered by<br></br> the unique digital token, meaning that buyers can feel safe in the knowledge of exactly where their wine is at all times.
<br></br><br></br>
VIK is the first winery in Chile to launch an NFT.

											</p>
										</div>
									</main>			<main className="main-content hero-content">
 
									<div className="youtube">
									<div className="youtube-video">
										<iframe 
											width="560" 
											title="vik"
											height="315" 
											src="https://www.youtube.com/embed/v63-3a-2q24" 
											frameborder="0" 
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
											allowfullscreen>
										</iframe>
									</div>

</div>	
</main>
	
							</div>
							<div className="main-app" id="main-app">
								

								<main className="main-content header-content">
									<div className="text-container">
										<p>
											Non-fungible tokens (NFTs) seem to be everywhere these days. From art and music to tweets and videos, these digital assets are part of the future of commerce, which is ironically, already here.
<br /><br></br>
For those who aren’t as familiar with Non-Fungible Tokens, in short, these are digital assets that represent both tangible and intangible elements, in this case, our exclusive wine. Each NFT has a unique signature that proves its authenticity, increasing its appeal in industries with high collectible value. “Non-fungibility” implies that they cannot be eliminated or exchanged for another good, unlike cryptocurrencies, or money, which can be exchanged between equal units.

										</p>
									</div>
								</main>
							</div>
							<div className="app" >
								<main
									className="main-content section-1-content">
									<div className="text-container">
										<div className="overflow-content">
											<h6>
												Unlike most digital goods, NFTs create
												digital scarcity, standing as <br />
												unrepeatable goods with high intrinsic
												value based on their rarity, <br />
												exclusivity, and price. <span>These
												characteristics are consistent with the
												high <br /> value that VIK products represent.</span>
												<br></br>
												<br></br>
												As our core values show, VIK's central
												impulse relies on innovation. <br /> Whereas in
												our production processes or our go-to
												market approach, we <br /> pride ourselves in
												being disruptive and finding new ways to
												bring you <br /> the very best of our unique
												products.
												<br></br>
												<br></br>
												This time, we decided to take that approach one step forward in our journey to increasingly incorporate new technologies as part of our product portfolio, which is why 2023 marks the launch of our first-ever NFT by VIK. After all, "Science and knowledge are our foundation, passion our engine and wine the expression of our art."

											</h6>
										</div>
									</div>
									<div className="image-container">
										<img
											src={wine2}
											alt="wine"
											className="right-side-image"
										/>
									</div>
								</main>
								<main
									className="main-content section-2-content">
									<div
										className="image-container"
										>
										<img
											src={wineContainer}
											alt="wine"
											className="right-side-image"
										/>
									</div>
									<div
										className="text-container"
										>
										<h6
										>
											To mark this milestone, we have selected our VIK 2018 edition – rated 98 points by James Suckling –. This unique blend of 75% Cabernet Sauvignon and 25% Cabernet Franc, was crafted under the watchful eye of Chief Winemaker Cristián Vallejo and aged for 26 months in French oak barrels. Sustainably produced in Chile’s Millahue “Golden Valley”, it embodies a dynamic, elegant, pure, yet tense and vibrant wine. VIK 2018 represents the most authentic and refined expression of our terroir.
										<br></br><br></br>
										Each bottle will be stored in the cellar of the vineyard under the highest standards and the care of our Chief Winemaker.  This means it will receive the care that its cellaring requires: it will be stored in a horizontal position at the constant temperature that has been especially determined for this wine in a cellar that is protected from noise, smells and vibrations and fully protected from direct light. The bottle shall remain under constant care until the buyer decides to collect it, which can be at any time from the moment it is acquired.  

										</h6>
									</div>
								</main>
								<main
									className="main-content section-3-content"
									>
									<div
										className="text-container"
										>
										<h6>
											This unique blend of 75% Cabernet
											Sauvignon and 25% Cabernet Franc, was
											crafted under the <br /> watchful eye of Chief
											Winemaker Cristián Vallejo and aged for
											26 months in French oak barrels. <br />
											Sustainably produced in Chile's Millahue
											“Golden Valley”, it embodies a dynamic,
											elegant, pure, yet <br /> tense and vibrant
											wine. VIK 2018 represents the most
											authentic and refined expression of our <br />
											terroir.
											
										</h6>
									</div>
									<div className="card-container" >
										<div className="text-card card-1">
										This is a unique opportunity to purchase a collectible edition of wine from Vik, which ranks 3rd among the World’s Best Vineyards 2023. <br></br>Furthermore, the nature of NFT is such that buyers receive a unique digital token, which is evidence of their ownership of the product they have bought, and with the reassurance that it cannot be forged or otherwise manipulated. 
										</div>
										<div className="text-card card-2"><br></br><br></br>
										Thanks to our NFT exchange platform, this digital asset represents a redeemable token, which can be cashed for a physical bottle of VIK 2018, and whose maximum amount cannot be copied or tampered with.
										<br></br><br></br><br></br>
										</div>
										<div className="text-card card-3">
										Given its exclusivity, VIK 2018 Limited Edition will be sold in an NFT format via our platform and unavailable for purchase from any retail store.<br></br> Other than that, those who visit our winery will also have the opportunity to try it. <br></br>This is in order to maintain the exclusiveness of our brand and the value-based characteristics of this luxury collectible. 

										</div>
									</div>
								</main>
							</div>
							<div className="app top-apps">
								<main className="main-content footer-content">
									<div className="text-container">
										<h2>
											The launch will be on our portal <br />
											<span>nft.vikwines.com</span> and the cost <br /> for each
											NFT will be 750 MATIC.
										</h2>
										<h6>
											Mark our words, this, is just the
											beginning. Our goal is not to limit
											ourselves to a single point <br /> of sale,
											aiming to be a multi-channel
											distributor, reaching new collectors and
											wine <br /> enthusiasts, both for our global
											loyal customers, and those embedded in
											this new digital age.
<br></br><br></br>
											We’ve also made a special Concierge service available to our customers in case they require assistance whilst purchasing their NFTs. 
Please contact <span>store@vik.cl </span>and we’ll be delighted to guide you through the process.

										</h6>
									</div>
									<div className="hr"></div>
									<div className="footer-image">
										<img src={ vik } alt="vik" />
									</div>
									<h4>&copy; 2023 VIKWINE. All rights reserved.</h4>
								</main>
							</div>{ " " }
							</section>
					)} 
					{ selectedOption === "ES" && (
						<section>
							<div className="app top-apps">
								 <header className="app-header">
									<a
										className="top-logo"
										href="https://www.vikwine.com/"
										target="_blank"
										rel="noopener noreferrer">
										<img src={logo} alt="logo" />
									</a>
									<div className="header-right">
										<button
											className="dropdown-button"
											onClick={() => setIsOpen(!isOpen)}>
											{ selectedOption || "EN" }
											 <i className="arrow down"></i>
										</button>
										{isOpen && (
											<ul
												className={`dropdown-menu ${
													isOpen ? "open" : ""
												}`}>
												{options.map((option, index) => (
													<li
														key={index}
														onClick={() =>
															handleOptionSelect(
																option
															)
														}>
														{option}
													</li>
												))}
											</ul>
										)}
										<button
											className="header-button"
											style={ {
												marginLeft: "10px",
												fontWeight: "500",
											}}
											onClick={goToClaimPage}>
											Claim
										</button>
									</div>
								</header> 
								<main className="main-content hero-content">
									<div className="hero-content-head">
										<div className="text-container">
											<h2 className="subtitle">
											VIK NFT
											</h2>
											<h3>
												2018 Limited Edition
											</h3>
										</div>
										<WalletInfo />
										</div>
										<div className="hero-text-container">
										<a href="#main-app">
													<i className="arrow down"></i> <span>LEARN MORE </span>
													</a>
										<p>
										La última innovación de Viña VIK es el lanzamiento de una edición limitada de 50 NFTs, ofreciendo exclusividad, trazabilidad y una inversión para la codiciada cosecha 2018.
 <br /> <br/>
 Esta edición limitada consiste en 50 NFTs ( ​​Non-Fungible Token) correspondientes cada uno a una botella<br></br> doble magnum de 3 litros de la cosecha 2018, la cual no ha sido lanzada al mercado.
<br></br>Gracias a la plataforma de intercambio de VIK, este activo digital representa un token canjeable,<br></br> por el que se podrá canjear una botella física de VIK 2018, y cuyo importe máximo no podrá ser copiado ni manipulado. Además – dictado por la naturaleza de NFT – los compradores recibirán un token digital único, que acredita
autoridad <br></br>sobre el producto que compraron, y con la seguridad de que no puede ser falsificado ni manipulado de ninguna otra forma.
<br></br><br></br>VIK es la primera viña en  Chile en lanzar un NFT.

										</p>
									</div>	
								</main>
								<main className="main-content hero-content">
 
									<div className="youtube">
									<div className="youtube-video">
										<iframe 
											width="560" 
											title="vik"
											height="315" 
											src="https://www.youtube.com/embed/v63-3a-2q24" 
											frameborder="0" 
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
											allowfullscreen>
										</iframe>
									</div>

</div>	
</main>
							</div>
							<div className="main-app" id="main-app">
								<main className="main-content header-content" >
									<div className="text-container">
										<p>
										Últimamente los tokens no fungibles (NFT) parecen estar en todos lados. Desde arte y música hasta tuits
y vídeos, estos activos digitales forman parte del futuro del comercio, el mismo que irónicamente, parece
ya estar aquí. Mientras algunos opinan que son una tendencia mainstream, otros creen que los NFT han
llegado para quedarse y que cambiarán la formas de invertir para siempre.

											<br></br>
											<br></br>
											Para los que no están familiarizados con los Non-Fungible Tokens, en pocas palabras, se trata de activos
digitales que representan elementos tanto tangibles como intangibles: en este caso, vino exclusivo. Cada
NFT tiene una firma única que demuestra su autenticidad, lo que aumenta su atractivo en industrias con
alto valor coleccionable. Dicha “no fungibilidad” implica que no pueden eliminarse ni cambiarse por otro
bien, a diferencia de las criptomonedas, o el dinero, que puede intercambiarse entre unidades iguales.

										</p>
									</div>
								</main>
								</div>
							<div className="app" >
								<main
									className="main-content section-1-content">
									<div className="text-container">
										<div className="overflow-content">
											<h6>A diferencia de la mayoría de los bienes digitales, las NFT crean escasez digital, alzándose como bienes
irrepetibles con un alto valor intrínseco basado en su rareza, exclusividad y precio, por lo que esta edición limitada de  NFT VIK 2018 forma parte de los esfuerzos de la viña  por trasladar creatividad e innovación a su cartera de productos.

										 <br></br>
											<br></br>
											Ya sea en su propuesta conceptual  como en la elaboración de sus vinos , una de las  características  de VIK radica en la innovación. Siguiendo su camino de incorporar cada vez más tecnología a su oferta, el
2023 marca el lanzamiento de su primer NFT by VIK. Como consigna su lema central: “La ciencia y el
conocimiento es nuestra base, la pasión nuestro motor y el vino la expresión de nuestro arte”.

											</h6>
										</div>
									</div>
									<div className="image-container">
										<img
											src={wine2}
											alt="wine"
											className="right-side-image"
										/>
									</div>
								</main>
								<main
									className="main-content section-2-content">
									<div
										className="image-container"
										>
										<img
											src={wineContainer}
											alt="wine"
											className="right-side-image"
										/>
									</div>
									<div
										className="text-container"
										>
										<h6
										>
											Para este hito hemos seleccionado nuestra cosecha VIK 2018 – calificada con 98 puntos por James Suckling – .
<br></br>
											<br></br>
											Esta mezcla única de 75% Cabernet Sauvignon y 25% Cabernet Franc, fue elaborada bajo la atenta
mirada del Enólogo Jefe Cristián Vallejo y envejecida durante 26 meses en barricas de roble francés.
Producido de manera sustentable en el “Valle de Oro” de Millahue, Chile.
Este vino refleja una personalidad expresiva, franca, honesta, elegante, pura y dinámica, pero a la vez tensa y vibrante. 
VIK 2018 representa la expresión más auténtica y refinada del terroir del viñedo hasta ahora.
 <br></br>
											<br></br>Cada botella se mantendrá en la bodega de la viña bajo los más altos estándares y al cuidado de nuestro enólogo jefe Cristián Vallejo. Esto significa seguir los lineamientos que su conservación requiere: temperatura constante especialmente determinada para este vino; cava protegida de ruidos, olores y vibraciones; posición horizontal y totalmente protegida de la luz directa. La botella  permanecerá bajo
cuidado perpetuo hasta que el comprador decida retirarla, lo que puede suceder en
cualquier momento desde su compra en adelante.

											
										</h6>
									</div>
								</main>
								<main
									className="main-content section-3-content"
									>
									<div
										className="text-container"
										>
										<h6>
										Se uno de los primeros en conseguir VIK 2018  NFT Edición Limitada

										</h6>
									</div>
									<div className="card-container" >
										<div className="text-card card-1">
										Esta botella no se podrá encontrar en ninguna tienda ya que la compra al público general no está
disponible. 


										</div>
										<div className="text-card card-2">
										Es una oportunidad única de adquirir una edición coleccionable de VIK,  ya que dicha añada en formato doble magnum  sólo se venderá en formato NFT y para el selecto grupo de clientes de VIK. 

										</div>
										<div className="text-card card-3">
										Con ello se pretende mantener la exclusividad de esta edición limitada y las
características de valor de este bien coleccionable de lujo

										</div>
									</div>
								</main>
							</div>
							<div className="app top-apps">
								<main className="main-content footer-content">
									<div className="text-container">
										<h2>
											El lanzamiento será en nuestro <br /> portal 
										<span> nft.vikwines.com </span> y el costo <br /> por cada NFT
											será de 750 MATIC.
										</h2>
										<h6>
											Recuerda nuestras palabras, esto, es
											solo el comienzo. Nuestro objetivo no es
											limitarnos a un único punto de venta,
											sino ser un distribuidor multicanal,
											llegando a nuevos coleccionistas y
											entusiastas del vino, tanto para
											nuestros clientes globales fieles como
											para los que están inmersos en esta
											nueva era digital.
											<br></br><br></br>También tendremos un servicio de “Concierge” para poder guiar en la compra a nuestros clientes. Por favor contactar a <span>store@vik.cl</span> y estaremos felices de ayudarte,
<br></br><br></br>
Con este lanzamiento,VIK se posiciona nuevamente como una viña pionera en Chile y Latinoamérica. 

										</h6>
									</div>
									<div className="hr"></div>
									<div className="footer-image">
										<img src={ vik } alt="vik" />
									</div>
									<h4>&copy; 2023 VIKWINE. All rights reserved.</h4>
								</main>
							</div>{ " " }
							</section>
					)} 
					{ selectedOption === "PT" && (
						<section>
							<div className="app top-apps">
								 <header className="app-header">
									<a
										className="top-logo"
										href="https://www.vikwine.com/"
										target="_blank"
										rel="noopener noreferrer">
										<img src={logo} alt="logo" />
									</a>
									<div className="header-right">
										<button
											className="dropdown-button"
											onClick={() => setIsOpen(!isOpen)}>
											{ selectedOption || "EN" }
											 <i className="arrow down"></i>
										</button>
										{isOpen && (
											<ul
												className={`dropdown-menu ${
													isOpen ? "open" : ""
												}`}>
												{options.map((option, index) => (
													<li
														key={index}
														onClick={() =>
															handleOptionSelect(
																option
															)
														}>
														{option}
													</li>
												))}
											</ul>
										)}
										<button
											className="header-button"
											style={ {
												marginLeft: "10px",
												fontWeight: "500",
											}}
											onClick={goToClaimPage}>
											Claim
										</button>
									</div>
								</header> 
								<main className="main-content hero-content">
									<div className="hero-content-head">
										<div className="text-container">
											<h2 className="subtitle">
											VIK NFT
											</h2>
											<h3>
												2018 Limited Edition
											</h3>
										</div>
										<WalletInfo />
										</div>
										<div className="hero-text-container">
										<a href="#main-app">
													<i className="arrow down"></i> <span>LEARN MORE </span>
													</a>
										<p>
										A mais recente inovação da Viña VIK é uma edição limitada de apenas 50 NFTs,<br></br> que
oferecem exclusividade, rastreabilidade e tranquilidade aos compradores do
renomado VIK 2018
 <br /> <br />O lançamento limitado consiste em 50 NFTs, sendo que cada um
representa uma garrafa double magnum <br></br>da edição especial do VIK 2018
NFT. Ao adquirir o NFT, o proprietário pode negociar seu ativo digital <br></br>ou
resgatá-lo como um token e receber a garrafa física do VIK 2018. <br></br>Um dos
benefícios dos NFTs de vinho é a rastreabilidade oferecida pelo token
digital exclusivo, o que significa que os compradores podem ficar
tranquilos ao saber onde o seu vinho está exatamente a todo momento.

 <br></br><br></br>
 A VIK é a primeira vinícola do Chile a lançar um NFT.

										</p>
									</div>
								</main>
								<main className="main-content hero-content">
 
									<div className="youtube">
									<div className="youtube-video">
										<iframe 
											width="560" 
											title="vik"
											height="315" 
											src="https://www.youtube.com/embed/v63-3a-2q24" 
											frameborder="0" 
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
											allowfullscreen>
										</iframe>
									</div>

</div>	
</main>
							</div>
							<div className="main-app" id="main-app">
								<main className="main-content header-content">
									<div className="text-container">
										<p>
										Ultimamente, os tokens não fungíveis (NFTs) parecem estar por todos os lados. Na arte, na música e até
mesmo em tuítes e vídeos, estes ativos digitais fazem parte do futuro do comércio. Futuro esse que,
ironicamente, parece já estar aqui.

<br></br>
											<br></br>
											Para os que não estão tão familiarizados com os Non-Fungible Tokens, em poucas palavras, são ativos
digitais que representam elementos tanto tangíveis como intangíveis. Neste caso, o nosso vinho exclusivo.
Cada NFT possui uma assinatura única que comprova a sua autenticidade, o que aumenta a atratividade
em indústrias com alto valor colecionável. Tal “infungibilidade”; implica que não podem ser eliminados
nem trocados por outro bem, diferentemente das criptomoedas ou do dinheiro, que podem ser trocados
entre unidades iguais.

										</p>
									</div>
								</main>
								</div>
							<div className="app" >
								<main
									className="main-content section-1-content">
									<div className="text-container">
										<div className="overflow-content">
											<h6>
											Ao contrário da maioria dos bens digitais, os NFTs criam escassez digital, sagrando-se como bens únicos
com um alto valor intrínseco baseado em sua raridade, exclusividade e preço. 
 <span> Essas características são
											consistentes com o alto valor que os
											produtos VIK representam. </span> <br></br>
											<br></br>Como demonstrado por nossos valores fundamentais, o impulso central da VIK radica na inovação. Seja
em nossos processos produtivos ou em nosso enfoque de mercado, estamos orgulhosos de sermos
disruptivos e de encontrarmos novas formas de entregar o melhor de nossos produtos únicos.
Desta vez, decidimos levar essa abordagem um passo à frente em nossa jornada para incorporar cada vez
mais novas tecnologias em nosso portfólio de produtos. É por essa razão que 2023 marca o lançamento do nosso primeiro NFT by VIK. 
<br></br><br></br>Afinal, “A ciência e o conhecimento são nossa base. A paixão é o que nos
move. O vinho é a expressão de nossa arte”

											</h6>
										</div>
									</div>
									<div className="image-container">
										<img
											src={wine2}
											alt="wine"
											className="right-side-image"
										/>
									</div>
								</main>
								<main
									className="main-content section-2-content">
									<div
										className="image-container"
										>
										<img
											src={wineContainer}
											alt="wine"
											className="right-side-image"
										/>
									</div>
									<div
										className="text-container"
										>
										<h6
										>
											Para marcar este momento, foi selecionada a nossa edição VIK 2018 — avaliada com 98 pontos por
James Suckling —. Este corte único de 75% de Cabernet Sauvignon e 25% de Cabernet Franc foi
elaborado sob o olhar atento do enólogo-chefe Cristián Vallejo e estagiou durante 26 meses em barricas
de carvalho francês. Produzido de forma sustentável no “vale de ouro” de Millahue, no Chile, encarna um
vinho de personalidade dinâmica, elegante e pura, mas ao mesmo tempo tenso e vibrante. O VIK 2018
representa a expressão mais autêntica e refinada do nosso terroir.
<br></br>
											<br></br>
											Cada garrafa será armazenada na adega da vinícola sob os mais elevados padrões e os cuidados de nosso
enólogo-chefe. Isso significa que receberá os cuidados que a sua conservação exige: será armazenada na
posição horizontal, à temperatura constante especialmente definida para este vinho, em uma cave protegida
de ruídos, odores e vibrações, além de totalmente protegida da luz direta. A garrafa permanecerá sob
cuidados constantes até que o comprador decida retirá-la, o que pode acontecer a qualquer momento
desde a sua compra.
<br></br>
											<br></br>
											
										</h6>
									</div>
								</main>
								<main
									className="main-content section-3-content"
									>
									<div
										className="text-container"
										>
										<h6>
										Seja um dos primeiros em adquirir o VIK 2018 NFT Edição Limitada
											
										</h6>
									</div>
									<div className="card-container" >
										<div className="text-card card-1">
										Esta é uma oportunidade única para adquirir uma edição colecionável do vinho da Vik, que ocupa o 3º
lugar entre as Melhores Vinícolas do Mundo de 2023. Além disso, pela natureza do NFT, os compradores
receberão um token digital único, que comprova a propriedade do produto comprado, além da garantia de
que não pode ser falsificado ou manipulado de nenhuma forma. 

										</div>
										<div className="text-card card-2"><br></br><br></br>
										Graças à nossa plataforma de troca de NFT, este ativo digital representa um token passível de resgate. Ou
seja, pode ser trocado por uma garrafa física do VIK 2018 e cujo montante máximo não pode ser copiado
ou adulterado.<br></br><br></br>

										</div>
										<div className="text-card card-3">
										Dada a sua exclusividade, o VIK 2018 Edição Limitada será vendido em formato NFT por meio
da nossa plataforma e não estará disponível nas lojas de varejo. Além disso, os visitantes de
nossa vinícola também terão a oportunidade de degustá-lo. Isto para manter a exclusividade da
nossa marca e as características de valor deste item colecionável de luxo.

										</div>
									</div>
								</main>
							</div>
							<div className="app top-apps">
								<main className="main-content footer-content">
									<div className="text-container">
										<h2>
											 O lançamento será em
											nosso portal <span> nft.vikwines.com </span> e o custo
											de cada NFT será de 750 MATIC.
										</h2>
										<h6>
											Marque nossas palavras, isso é apenas o
											começo. O nosso objetivo é não nos
											limitarmos a um único ponto de venda,
											pretendendo ser um distribuidor
											multicanal, alcançando novos
											colecionadores e apreciadores de vinho,
											tanto para os nossos fiéis clientes
											globais, como para os inseridos nesta
											nova era digital.<br></br><br></br>
											Também disponibilizamos um serviço especial de concierge aos nossos clientes, caso necessitem de assistência durante a compra de seus NFTs. 
Em caso de dúvidas, entre em contato com <span>store@vik.cl</span>. Estaremos felizes em orientá-lo durante o processo.

										</h6>
									</div>
									<div className="hr"></div>
									<div className="footer-image">
										<img src={ vik } alt="vik" />
									</div>
									<h4>&copy; 2023 VIKWINE. All rights reserved.</h4>
								</main>
							</div>{ " " }
							</section>
					)} 
					</div>
			</RainbowKitProvider>
		</WagmiConfig>
	);
}

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<App2 />}></Route>
				<Route path="/claim" element={<Claim />} />
			</Routes>
		</Router>
	);
}

export default App;
