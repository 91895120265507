import React, { useState, useEffect } from 'react';
import { useAccount, WagmiConfig, createClient, useContract } from 'wagmi';
import { ethers } from 'ethers';
import Web3 from 'web3';
import { ConnectButton, RainbowKitProvider, getDefaultWallets, darkTheme, wallet } from '@rainbow-me/rainbowkit';
import merge from 'lodash.merge';
import {  chain, configureChains } from 'wagmi';
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import "@rainbow-me/rainbowkit/styles.css";
import wine from './bottle.png';
import wine2 from './wine2.png';
import vik from './vik.png';
import wineContainer from './wineContainer.jpg';
import logo from './logo.svg';
import './App.css';
import ABI from './abi.json'
import { useNavigate, useLocation, Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import Airtable from 'airtable';
import { polygon, sepolia } from 'viem/chains';





const { chains, provider } = configureChains(
  [polygon],
  [alchemyProvider({ alchemyId: process.env.REACT_APP_ALCHEMY_ID }), publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: "My RainbowKit App",
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
});

const API_KEY = process.env.REACT_APP_API_KEY;
const BASE_ID = process.env.REACT_APP_BASE_ID;
const TABLE_NAME = process.env.REACT_APP_TABLE_NAME;

const base = new Airtable({ apiKey: API_KEY }).base(BASE_ID);


function WalletInfo() {
  const account = useAccount();
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [mintAmount, setMintAmount] = useState(1); // Initial mint amount
  const [isTransactionSuccessful, setIsTransactionSuccessful] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    lastName: '',
    address: '',
    city: '',
    country: '',
    email: '',
    phone: '',
    acceptInfo: false,
  });


  const navigate = useNavigate();

  const handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setFormData({
      ...formData,
      [event.target.name]: value
    });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    await mintNFT();
  }


  const goToClaimPage = () => {
    navigate('/claim');
  }
  
  const handleDecrement = () => {
    if (mintAmount > 1) {
      setMintAmount(mintAmount - 1);
    }
  };

  const handleIncrement = () => {
    if (mintAmount < 10) {
      setMintAmount(mintAmount + 1);
    }
  };

  const contractAddress = '0x8b429db808e5c13bc9d63976c6aecf17411fbb12';

  const mintNFT = async () => {
    try {
      const contractAddress = '0x8b429db808e5c13bc9d63976c6aecf17411fbb12'; 
      if (!account || !account.connector) {
        alert('Please connect your wallet first.');
        return;
      }
      const signer = await account.connector.getSigner();
      if (!signer) {
        alert('Unable to get signer from the connected wallet');
        return;
      }

      const contract = new ethers.Contract(contractAddress, ABI, signer);
      if (!contract) {
        alert('Unable to create contract instance');
        return;
      }


      const transaction = await contract.claim();
      const transactionReceipt = await transaction.wait();

      if (transactionReceipt.status === 1) {
        setIsTransactionSuccessful(true);
      }
    } catch (error) {
      console.error(error);
      alert('NFT minting failed');
    }
  }

  useEffect(() => {
    if (account && account.connector) {
      setIsWalletConnected(true);
      setWeb3(new Web3(account.connector.provider));
    }
  }, [account]);

  useEffect(() => {
    if (isTransactionSuccessful) {
      try {
        base(TABLE_NAME).create([
          {
            fields: {
              'Name': formData.name,
              'Last Name': formData.lastName,
              'Address': formData.address,
              'City': formData.city,
              'Country': formData.country,
              'Email': formData.email,
              'Phone': formData.phone,
              'Accept Info': formData.acceptInfo,
            }
          },
        ]);
      } catch (error) {
        console.error(error);
        alert("Oops, there was an error. Please try again.");
      }
    }
  }, [isTransactionSuccessful]);



  return (
    < div className='claim-form'>
        {isWalletConnected ? <form onSubmit={handleSubmit}>
				<div>
					<input type="text" name="name"  onChange={handleChange}  placeholder='Name'/>
					<input type="text" name="lastName" onChange={handleChange} placeholder='Last Name' />
				</div>
				<div>
					<input type="text" name="address" onChange={handleChange} placeholder='Address' className='address-input' />
				</div>
				<div>
					<input type="text" name="city" onChange={handleChange} placeholder='City'/>
					<input type="text" name="country" onChange={handleChange} placeholder='Country' />
				</div>
				<div>
					<input type="email" name="email" onChange={handleChange} placeholder='Email'/>
					<input type="tel" name="phone" onChange={handleChange} placeholder='Phone' />
				</div>
				<div className='claim-check'>
					<input type="checkbox" name="acceptInfo" onChange={handleChange} />
					I accept to receive information from VIK Winery
			  </div>
		  </form> : <div className="image-container2">
				  <img src={ wine } alt="wine" className="right-side-image" /> 
		  </div> }
		  <div className='claim-submit' >
				<ConnectButton label="Claim Now" accountStatus={ "address" } chainStatus="none" showBalance={ false } />
			</div>
		  
		  {isWalletConnected ? (
				  isTransactionSuccessful ? (
					   <div className='claim-submit'>
						   <p style={{textAlign: "center", marginTop: "20px", color: "red"}}>Your order was placed! Thank you.</p>
					   </div>
			  ) : (
					  <div className='claim-submit'>
						  <button className='header-button' onClick={ mintNFT }>Confirm</button>
						  </div>
            )
        ) : ''}
    </div>
);

}

function Claim() {

 
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('EN');
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const goToClaimPage = () => {
    navigate('/');
  }

  const options = ['EN', 'ES', 'PT']; // Define your options here


  return (
    
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider theme={darkTheme({
      accentColor: '#A32036',
      accentColorForeground: 'white',
      borderRadius: 'none',
      fontStack: 'rounded',
      overlayBlur: 'small',
    })}  chains={chains}>
			  { selectedOption === 'EN' && 
				  	<section className='claim-section'>
						<div className="app claim-app" >
						<header className="app-header">
						<a className='top-logo' href="https://www.vikwine.com/" target="_blank" rel="noopener noreferrer">
								<img src={logo} alt="logo" />
							</a>
							<div className='header-right'>
								<button className="dropdown-button" onClick={() => setIsOpen(!isOpen)}> { selectedOption || 'EN' }
									<i className="arrow down"></i>
								</button>
								{isOpen && (
								<ul className={`dropdown-menu ${isOpen ? 'open' : ''}`}>
									{options.map((option, index) => (
									<li key={index} onClick={() => handleOptionSelect(option)}>
										{option}
									</li>
									))}
								</ul>
								)}<button className='header-button' style={{   marginLeft:'10px', fontWeight:'500'}} onClick={goToClaimPage}>Mint</button>
							
							</div>
						</header>
							<main className="main-content hero-content">
								<div className="text-container">
									<h2 className="subtitle">
										NFT VIK Winery
									</h2>
									<h3>
										2018 Limited Edition
								  </h3>
								  <p className='claim-text'>
									  If you own our NFT, you can now order your VIK 2018 bottle.
								  </p>
									<button 
									className='header-button claim-button'
									onClick={ () => navigate('/') }>Mint Now</button> 
							  </div>
							  <WalletInfo />
							</main>
					  </div>
					  <div className="app top-apps">
						  <main className="main-content footer-content">
									<div className="hr"></div>
									<div className="footer-image">
										<img src={ vik } alt="vik" />
									</div>
									<h4>&copy; 2023 VIKWINE. All rights reserved.</h4>
								</main>
							</div>{ " " }
					</section>}
			  { selectedOption === 'ES' && 
				  	<section>
						<div className="app claim-app" >
						<header className="app-header">
						<a className='top-logo' href="https://www.vikwine.com/" target="_blank" rel="noopener noreferrer">
								<img src={logo} alt="logo" />
							</a>
							<div className='header-right'>
								<button className="dropdown-button" onClick={() => setIsOpen(!isOpen)}> { selectedOption || 'ES' }
									<i className="arrow down"></i>
								</button>
								{isOpen && (
								<ul className={`dropdown-menu ${isOpen ? 'open' : ''}`}>
									{options.map((option, index) => (
									<li key={index} onClick={() => handleOptionSelect(option)}>
										{option}
									</li>
									))}
								</ul>
								)}<button className='header-button' style={{   marginLeft:'10px', fontWeight:'500'}} onClick={goToClaimPage}>Mint</button>
							
							</div>
						</header>
							<main className="main-content hero-content">
								<div className="text-container">
									<h2 className="subtitle">
										NFT VIK Winery
									</h2>
									<h3>
										2018 Limited Edition
								  </h3>
								  <p className='claim-text'>
									  Si eres dueño de nuestro NFT, ya puedes pedir tu botella VIK 2018.
								  </p>
									<button 
									className='header-button claim-button'
									onClick={ () => navigate('/') }>Mint Ahora</button> 
							  </div>
							  <WalletInfo />
							</main>
					  </div>
					  <div className="app top-apps">
						  <main className="main-content footer-content">
									<div className="hr"></div>
									<div className="footer-image">
										<img src={ vik } alt="vik" />
									</div>
									<h4>&copy; 2023 VIKWINE. All rights reserved.</h4>
								</main>
							</div>{ " " }
					</section>}
			  { selectedOption === 'PT' && 
				  	<section>
						<div className="app claim-app" >
						<header className="app-header">
						<a className='top-logo' href="https://www.vikwine.com/" target="_blank" rel="noopener noreferrer">
								<img src={logo} alt="logo" />
							</a>
							<div className='header-right'>
								<button className="dropdown-button" onClick={() => setIsOpen(!isOpen)}> { selectedOption || 'PT' }
									<i className="arrow down"></i>
								</button>
								{isOpen && (
								<ul className={`dropdown-menu ${isOpen ? 'open' : ''}`}>
									{options.map((option, index) => (
									<li key={index} onClick={() => handleOptionSelect(option)}>
										{option}
									</li>
									))}
								</ul>
								)}<button className='header-button' style={{   marginLeft:'10px', fontWeight:'500'}} onClick={goToClaimPage}>Mint</button>
							
							</div>
						</header>
							<main className="main-content hero-content">
								<div className="text-container">
									<h2 className="subtitle">
										NFT VIK Winery
									</h2>
									<h3>
										2018 Limited Edition
								  </h3>
								  <p className='claim-text'>
									 Se tens o nosso NFT, já podes pedir a tua garrafa VIK 2018.
								  </p>
									<button 
									className='header-button claim-button'
									onClick={ () => navigate('/') }>Mint Agora</button> 
							  </div>
							  <WalletInfo />
							</main>
					  </div>
					  <div className="app top-apps">
						  <main className="main-content footer-content">
									<div className="hr"></div>
									<div className="footer-image">
										<img src={ vik } alt="vik" />
									</div>
									<h4>&copy; 2023 VIKWINE. All rights reserved.</h4>
								</main>
							</div>{ " " }
					</section>}
      </RainbowKitProvider>
     </WagmiConfig>
  );
}

export default Claim;